$(function () {
  var imgAnimate = $('.js-img-animate');

  if ($('div').is(imgAnimate)) {
    var win = $(window);

    imgAnimate.each(function () {
      var img = $(this);
      var imgPosition = img.offset().top;
      var imgMove = img.find('.js-img-animate-move');
      var imgsMove = img.find('.js-img-animate-move2');

      // img.on('click', function () {
      //   imgMove.addClass('active');
      //   imgsMove.addClass('active');
      // })

      win.on('scroll', function () {
        var scrollPosition = win.scrollTop();

        if (scrollPosition + win.height() / 2 > imgPosition + img.height() / 2 - 100) {
          imgMove.addClass('active');
          imgsMove.addClass('active');
        }
      }).scroll();
    })
  }
});
