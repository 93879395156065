var articlePreviewTemplate = require("pug-loader!../../../client-templates/spray/articlePreview.pug");
var typo = require('ru-typo/build/bundle.js');

$(function () {
    var articlesPreviewList = $('.js_previewList');
    if (articlesPreviewList.length === 0) {
        return;
    }
    var loadMoreArticlesButton = $('.js_loadMoreArticles');
    var articles;
    var loadedArticlesCnt = 0;
    var ARTICLES_FIRST_LOAD_CNT = 6;
    var ARTICLES_LOAD_CNT = 6;
    articlesPreviewList.empty();
    $.getJSON('/assets/data/articles.json').then(function(data) {
        //console.log(data);
        articles = data.articles;
        showArticles(articles, ARTICLES_FIRST_LOAD_CNT);
    });

    loadMoreArticlesButton.on('click', function(e) {
        e.preventDefault();
        showArticles(articles, ARTICLES_LOAD_CNT);
    });

    function showArticles(articles, showCnt) {
        for (var i = 0; loadedArticlesCnt < articles.length && i < showCnt; i++) {
            var article = articles[loadedArticlesCnt++];
            articlesPreviewList.append(typo(articlePreviewTemplate({
                data: {
                    url: article.url,
                    img: article.previewImg,
                    title: article.title,
                    text: article.previewText,
                    text2: article.date
                }
            })));
        }
        if (loadedArticlesCnt === articles.length) {
            loadMoreArticlesButton.hide();
        }
    }
});
