$(function () {
  var win = $(window);
  var hero = $('.js_HeroStart');
  var items = hero.find('.js_HeroStartItem');
  var arrows = hero.find('.js_HeroStartArrows');

  items.each(function () {
    var item = $(this);
    var item2 = item.siblings(items);

    var itemLeft = items.first();
    var itemRight = items.last();

    item
      .on('mouseenter', function () {
        if (item.is(itemLeft)) {
          arrows.addClass('move-right');
        }
        else {
          arrows.addClass('move-left');
        }

        item.addClass('wide');
        item2.addClass('narrow');
      })
      .on('mouseleave', function () {
        arrows.removeClass('move-right');
        arrows.removeClass('move-left');

        item.removeClass('wide');
        item2.removeClass('narrow');
      })
  })

});
