$(function () {
  var buttons = $('.js-show-more');

  buttons.each(function () {
    var button = $(this);
    var label = button.text();
    var labelActive = button.data('label-active');

    var text = button.siblings('.js-show-more-text');

    if (!('ontouchstart' in document.documentElement)) {
      button
      .on('mouseover', function () {button.addClass('hover')})
      .on('mouseout', function () {button.removeClass('hover')})
    }

    button.on('click', function () {

      if (!text.hasClass('active')) {
        text.addClass('active');
        button.text(labelActive);
      }
      else {
        text.removeClass('active');
        button.text(label);
      }

      return false;
    })

  })
})
