var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (data, svgList) {pug_mixins["svg"] = pug_interp = function(svg){
var block = (this && this.block), attributes = (this && this.attributes) || {};
// iterate svgList
;(function(){
  var $$obj = svgList;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var item = $$obj[pug_index0];
if (svg == item.name) {
pug_html = pug_html + (null == (pug_interp = item.svg) ? "" : pug_interp);
}
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var item = $$obj[pug_index0];
if (svg == item.name) {
pug_html = pug_html + (null == (pug_interp = item.svg) ? "" : pug_interp);
}
    }
  }
}).call(this);

};
pug_mixins["button"] = pug_interp = function(tag){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C" + (tag) + (pug.attrs(pug.merge([{"class": "button"},attributes]), true)) + "\u003E\u003Cspan class=\"button__inner\"\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fspan\u003E\u003C\u002F" + (tag) + "\u003E";
};
pug_mixins["preview"] = pug_interp = function(data){
var block = (this && this.block), attributes = (this && this.attributes) || {};
var pathImg = '/assets/img/';
pug_html = pug_html + "\u003Cdiv class=\"preview\"\u003E\u003Ca" + (" class=\"preview__img-wrap\""+pug.attr("href", "#" + data.url, true, true)) + "\u003E\u003Cdiv class=\"preview__img\"\u003E\u003Cimg" + (pug.attr("src", pathImg + "/useful/" + data.img, true, true)+pug.attr("title", data.tex, true, true)+pug.attr("alt", data.title, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"preview__title js_typo\"\u003E" + (null == (pug_interp = data.title) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E\u003Cdiv class=\"preview__text-wrap\"\u003E \u003Cdiv class=\"preview__text js_typo\"\u003E" + (null == (pug_interp = data.text) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"preview__text2 js_typo\"\u003E" + (null == (pug_interp = data.text2) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};

































pug_html = pug_html + "\u003Cdiv class=\"preview-list__item\"\u003E";
pug_mixins["preview"](data);
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"svgList" in locals_for_with?locals_for_with.svgList:typeof svgList!=="undefined"?svgList:undefined));;return pug_html;};
module.exports = template;