var articleTemplate = require("pug-loader!../../../client-templates/spray/article.pug");
var similarArticleTemplate = require("pug-loader!../../../client-templates/spray/similarArticle.pug");
var articlePreviewTemplate = require("pug-loader!../../../client-templates/spray/articlePreview.pug");
var typo = require('ru-typo/build/bundle.js');

$(function() {
    var ARTICLES_FIRST_LOAD_CNT = 6;
    var ARTICLES_LOAD_CNT = 6;

    var loadedArticlesCnt = 0;
    var articlesCache = [];

    var articleContainer = $('.js_articleContainer');
    if (articleContainer.length === 0) {
        return;
    }
    var articlesPreviewList = $('.js_indexPreviewList');

    var articles = null;
    var articleUrl = null;


    function followAnchor() {
        var hash = window.location.hash;
        if (hash && hash.length > 1) {
            articleUrl = hash.substr(1);
        } else {
            articleUrl = null;
        }

        $('body, html').animate({scrollTop: 0}, 500);
        var foundArticle;
        if (articleUrl && (foundArticle = articles.find(function(article) {return article.url === articleUrl;}))) {
            showArticle(foundArticle);
        } else {
            showIndex();
        }
    }

    $.getJSON('/assets/data/articles.json').then(function(data) {
        articles = data.articles;
        followAnchor();
    });

    function getArticleContent(article) {
        var dfd = new $.Deferred();
        if (articlesCache[article.id]) {
            if (articlesCache[article.id] === 'not found') {
                dfd.reject();
            } else {
                dfd.resolve(articlesCache[article.id]);
            }
        } else {
            $.get('/assets/data/' + article.contentUrl).done(function(data) {
                articlesCache[article.id] = data;
                dfd.resolve(articlesCache[article.id]);
            }).fail(function() {
                articlesCache[article.id] = 'not found';
                dfd.reject();
            });
        }
        return dfd.promise();
    }

    function showArticle(article) {

        getArticleContent(article).done(function(articleContent) {
            $('.js_index').hide();
            $('.js_indexStuff').hide().find('.js_h1').replaceWith(function() {
                return $("<span>", {
                    class: this.className
                }).html(this.innerHTML);
            });
            $('.js_similarArticles').show().parent().show();
            $('.js_back').show();
            $('.js_articleContainer').show().html(typo(articleTemplate({
                data: {
                    title: article.title,
                    author: article.author,
                    published: article.published,
                    fullText: articleContent
                }
            }))).parent().show();
            showSimiliar(article);
        }).fail(function() {
            showIndex();
        });
    }

    function showSimiliar(article) {
        var similarArticlesList = $('.js_similarArticlesList');
        similarArticlesList.empty();
        var similarCnt = 0;

        for (var i = 0; i < articles.length; i++) {
            var similarArticle = articles[i];
            if (article.similar && article.similar.indexOf(similarArticle.id) >= 0) {
                similarCnt++;
                similarArticlesList.append(typo(similarArticleTemplate({
                    data: {
                        url: similarArticle.url,
                        img: similarArticle.previewImg,
                        title: similarArticle.title,
                        text: similarArticle.previewText,
                        text2: similarArticle.date
                    }
                })));
            }
        }

        $('.js_similarArticles').toggle(similarCnt > 0);
    }

    function showIndex() {
        $('.js_index').show();
        $('.js_indexStuff').show().find('.js_h1').replaceWith(function() {
            return $("<h1>", {
                class: this.className
            }).html(this.innerHTML);
        });
        $('.js_articleContainer').hide().empty().parent().hide();
        $('.js_similarArticles').hide().parent().hide();
        $('.js_back').hide();

        if (loadedArticlesCnt === 0) {
            showArticles(articles, ARTICLES_FIRST_LOAD_CNT);
        }
    }

    var loadMoreArticlesButton = $('.js_loadMoreArticles');

    loadMoreArticlesButton.on('click', function(e) {
        e.preventDefault();
        showArticles(articles, ARTICLES_LOAD_CNT);
    });

    function showArticles(articles, showCnt) {
        for (var i = 0; loadedArticlesCnt < articles.length && i < showCnt; i++) {
            var article = articles[loadedArticlesCnt++];

            articlesPreviewList.append(typo(articlePreviewTemplate({
                data: {
                    url: article.url,
                    img: article.previewImg,
                    title: article.title,
                    text: article.previewText,
                    text2: article.date
                }
            })));
        }
        if (loadedArticlesCnt === articles.length) {
            loadMoreArticlesButton.hide();
        }
    }

    window.addEventListener('hashchange', followAnchor);
});
