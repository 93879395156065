$(function () {
  var tabs = $('.js-tabs');

  tabs.each(function () {
    var tab = $(this);
    var links = tab.find('.js-tabs-link');
    var contentsBlocks = tab.find('.js-tabs-content-box');

    links.each(function (index) {
      var link = $(this);

      link.on('click', function () {

        contentsBlocks.each(function () {
          var block = $(this);
          var content = $(block.find('.js-tabs-content'));

          content.removeClass('active');
          $(content[index]).addClass('active');
        })

        links.parent().removeClass('active');
        link.parent().addClass('active');

        return false;
      })
    })
  })
})
