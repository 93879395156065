$(function () {
  var slider = $('.js-steps');
  var list = slider.find('.js-steps-list');
  var dots = slider.find('.js-steps-dots');

  list.owlCarousel({
    dotsContainer: dots,
    responsive: {
      0: {
        mouseDrag: true,
        items: 1
      },
      600: {
        mouseDrag: false,
        items: 3
      },
      768: {
        mouseDrag: true,
        items: 1
      },
      1200: {
        mouseDrag: false,
        items: 3
      }
    }
  });
});


$(function () {
  var slider = $('.js-steps2');
  var list = slider.find('.js-steps2-list');
  var dots = slider.find('.js-steps2-dots');

  list.owlCarousel({
    dotsContainer: dots,
    responsive: {
      0: {
        items: 1
      },
      992: {
        mouseDrag: false
      }
    }
  });
});
