$(function () {
  var modals = $('.js-modal');
  var modalsStub = $('.js-modal-stub');
  var openModals = $('.js-modal-open');
  var page = $('body');

  if ($.cookie('scpecOpen')) {
    modalsStub.hide();
    page.removeClass('no-scroll');
  }
  else {
    modalsStub.addClass('active');
  }

  openModals.each(function () {
    var open = $(this);
    var target = open.data('target');
    var modal = $('#'+target);

    open.on('click', showModal)

    function showModal() {
      modal.addClass('active');
      page.addClass('no-scroll');
      return false;
    }

  })

  modals.each(function () {
    var modal = $(this);
    var modalBody = modal.find('.js-modal-body');
    var close = modal.find('.js-modal-close');

    if (!modal.hasClass('js-modal-stub')) {

      modal.on('click', function (e) {
        if ($(e.target).is('.js-modal-body')) {
          hideModal()
        }
      })

      close.on('click', hideModal);

      $(document).keyup(function(e){
        if(e.keyCode === 27) {
          hideModal()
        }
      });

    }
    else {
      close.on('click', function () {
        $.cookie('scpecOpen', true, {path: '/'})
        hideModal();
      });
    }

    function hideModal() {
      modal.removeClass('active');
      page.removeClass('no-scroll');
      return false;
    }
  })
})
