$(function () {
  var links = $('.js-scroll-link');
  var targets = $('.js-scroll-target');
  var navSectionsList = $('.js_navSectionsList');
  var nav = $('.js-nav-sections');
  var win = $(window);
  var page = $('body, html');
  var linkPadding = parseFloat(links.parent().css('padding-left'));
  var isAnimating = false;
  var ANIMATION_DURATION = 500;

  var targetsByIndex = [];
  
  function isTargetActive(idx) {
    var scrollPosition = win.scrollTop();
    var targetPosition = targetsByIndex[idx].offset().top;
    return scrollPosition >= targetPosition ||
        scrollPosition > targetPosition + 10  ||
        scrollPosition > targetPosition - 10;
  }

  function fixLinkVisibility(link, force) {
    if (navSectionsList.width() > nav.width() && (!isAnimating || force)) {
      var idx = links.index(link);
      if (link.position().left + link.width() > nav.width()) {
        isAnimating = true;
        nav.stop().animate({
          scrollLeft: nav.scrollLeft() - nav.width() + link.position().left + link.width() + linkPadding
        }, {
          duration: ANIMATION_DURATION,
          complete: function() {
            isAnimating = false;
          }
        });
      } else if (link.position().left < 0 &&
          (force || (idx < links.length - 1 && !isTargetActive(idx + 1)))
      ) {
        isAnimating = true;
        nav.stop().animate({
          scrollLeft: nav.scrollLeft() + link.position().left - linkPadding
        }, {
          duration: ANIMATION_DURATION,
          complete: function() {
            isAnimating = false;
          }
        });
      } else {
        if (force) {
          // force animate to current slide, but current slide fully visible -
          // so prevent other animations (on scroll) to stay current slide visible
          isAnimating = true;
          setTimeout(function() {
            isAnimating = false;
          }, ANIMATION_DURATION)
        }
      }
    }
  }

  links.each(function (idx, el) {
    var link = $(this);
    var linkData = link.data('target');
    var target = targets.filter(function(i){return $(targets[i]).data('target') === linkData});
    targetsByIndex[idx] = target;

    win.on('scroll', function () {
      if (isTargetActive(idx)) {
        links.removeClass('active');
        link.addClass('active');
        fixLinkVisibility(link);
      } else {
        link.removeClass('active');
      }
    }).scroll();

    link.on('click', function () {
      var targetPosition= target.offset().top;
      fixLinkVisibility(link, true);
      page.animate({
        scrollTop: targetPosition
      }, {
        duration: ANIMATION_DURATION
      });
      // links.removeClass('active');
      // link.addClass('active');
      return false;
    });
  });

  if ($('div').is(nav)) {

    var header = $('.js-header');
    var headerHeight;
    var navPosition = nav.offset().top;

    win.on('resize', function() {
      headerHeight = header.innerHeight();
    }).resize();

    var prevScrollPosition = 0;

    win.on('scroll', function () {
      var scrollPosition = win.scrollTop();

      if (scrollPosition + headerHeight > navPosition) {
        nav.addClass('fixed');
        if (scrollPosition < prevScrollPosition) {
          header.removeClass('hidden').css({top: 0});
          nav.css({
            'top': headerHeight + 'px'
          });
        } else {
          header.addClass('hidden').css({top: -headerHeight});
          nav.css('top', 0);
        }
        header.addClass('no-shadow');
      } else {
        nav.removeClass('fixed').attr('style','');
        header.removeClass('no-shadow').removeClass('hidden').css({top: 0});
      }

      /*if (scrollPosition + headerHeight > navPosition) {
        header.addClass('hidden').css({top: -headerHeight});
      }
      else {
        header.removeClass('hidden').css({top: 0});
      }*/
      prevScrollPosition = scrollPosition;
    }).scroll();

  }

});
