$(function () {
  var slider = $('.js-slider');
  var container = slider.find('.js-slider-list');
  var arrows = slider.find('.js-slider-arrows');
  var dots = slider.find('.js-slider-dots');

  container.on({'initialized.owl.carousel': function () {
      container.find('.slider__item').show();
    }
  })

  container.owlCarousel({
      loop: true,
      rewind: true,
      items: 1,
      dotsContainer: dots,
      navContainer: arrows,
      navText: [
        '<svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="8px" height="12px" viewBox="0 0 8 12" enable-background="new 0 0 8 12" xml:space="preserve" class="svg-triangle-left svg-fill" fill="#AF05ED"><path d="M0,6l8-6v12L0,6z"/></svg>', '<svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="8px" height="12px" viewBox="0 0 8 12" enable-background="new 0 0 8 12" xml:space="preserve" class="svg-triangle-right svg-fill" fill="#AF05ED"><path d="M8,6l-8,6V0L8,6z"/></svg>'
      ]
    })

    container.on('changed.owl.carousel', function(event) {
      $('.owl-item.cloned').css({opacity: 1})
    })
});
