$(function () {
  var navToggle = $('.js-nav-toggle');
  var nav = $('.js-nav-mobile');
  var page = $('body');

  navToggle.on('click', function () {
    $(this).toggleClass('active');
    nav.toggleClass('active');
    page.toggleClass('no-scroll');
  })
})
