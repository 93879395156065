$(function () {
  var dropdownTitles = $('.js-dropdown-title');

  dropdownTitles.each(function () {
    var title = $(this);
    var wrap = title.parent();

    title.on('click', function () {
      wrap.toggleClass('active');
    })

  })
})
