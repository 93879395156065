require('zurb-twentytwenty/js/jquery.event.move.js');
require('zurb-twentytwenty/js/jquery.twentytwenty.js');


$(window).on('load', function () {
  var imgs = $('.js_splitItems');

  imgs.each(function () {
    var imgBox = $(this);
    var img = imgBox.find('img');

    console.log(img.width());

    imgBox.twentytwenty({no_overlay: true});

    var arrows = img.find('.twentytwenty-handle');

    $('body').on('mousemove', function () {
      console.log(img.width());
    });

    console.log(arrows);
  })

});
