$(function () {
  var slider = $('.js-slider2');
  var list = slider.find('.js-slider2-list');
  var dots = slider.find('.js-slider2-dots');

  list.owlCarousel({
    dotsContainer: dots,
    responsive: {
      0: {
        loop:true,
        items: 1
      },
      992: {
        mouseDrag: false,
        loop:false,
        items: 3
      }
    }
  });
});

$(function () {
  var slider = $('.js-slider3');
  var list = slider.find('.js-slider3-list');
  var dots = slider.find('.js-slider3-dots');

  list.owlCarousel({
    dotsContainer: dots,
    arrows: false,
    responsive: {
      0: {
        loop:true,
        items: 1
      },
      992: {
        mouseDrag: false,
        loop:false,
        items: 4
      }
    }
  });
});
