var $ = require('jquery')

require('owl.carousel');

require('./jquery.cookie.js');
require('./warning.min.js');
require('./common.js');

require('../../../sections/spray/header/header.js')
require('../../../sections/spray/hero/hero.js')
require('../../../sections/spray/hero-start/hero-start.js')
require('../../../sections/spray/slider/slider.js')
require('../../../sections/spray/useful/useful.js')
require('../../../sections/spray/article/article.js')

require('../../../components/spray/effect-list/effect-list.js')
require('../../../components/spray/step-list/step-list.js')
require('../../../components/spray/split-imgs/split-imgs.js')
require('../../../components/spray/dropdown-text/dropdown-text.js')
require('../../../components/spray/nav-sections/nav-sections.js')
require('../../../components/spray/effect/effect.js')
require('../../../components/spray/img-animate/img-animate.js')
require('../../../components/spray/show-more/show-more.js')
require('../../../components/spray/tabs/tabs.js')
require('../../../components/spray/alert/alert.js')
require('../../../components/spray/modal/modal.js')

require('./typo.js');
