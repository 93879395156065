$(window).on('load', function() {
  var effects = $('.js-effect');

  effects.each(function () {
    var effect = $(this);

    var content = effect.find('.js-effect-content');
    var contentOffset;
    var contentOffsetPlus;

    var contentHidden = effect.find('.js-effect-hidden-content');
    var contentHiddenHeight = contentHidden.innerHeight();
    var contentTextHeight = effect.find('.js-effect-text').innerHeight();

    var more = effect.find('.js-effect-more');
    var moreLabel = more.find('.js-effect-more-label');
    var moreText = moreLabel.text();
    var moreTextActive = more.data('text-active');

    var win = $(window);


    win.resize(function () {

      contentOffset = more.offset().top - effect.offset().top;

      if (window.matchMedia('all and (max-width: 767px)').matches) {
        contentOffsetPlus = -9;
      }
      else {
        contentOffsetPlus = 22;
      }

    }).resize()

    more.on('click', function () {

      if (!more.hasClass('active')) {
        content.css({marginTop: -contentOffset + contentOffsetPlus}).addClass('hidden');
        contentHidden.show().css({height: contentTextHeight}).addClass('visible');
        more.addClass('active');
        moreLabel.text(moreTextActive);
      }
      else {
        content.css({marginTop: 0}).removeClass('hidden');
        contentHidden.css({height: contentHiddenHeight}).removeClass('visible');
        more.removeClass('active')
        moreLabel.text(moreText);
      }
    })
  })
})
